import React from 'react'


import Faq from '../../Faq/Faq'


import HireArchitechBanner from './hirearchitechcompo/HireArchitechBanner'
import HireArchitechBenifits from './hirearchitechcompo/HireArchitechBenifits'
import HireArchitechBanner2 from './hirearchitechcompo/HireArchitechBanner2'
import HireArchitechReadyBanner from './hirearchitechcompo/HireArchitechReadyBanner'
import CertificateSlider from '../../../Home/Component/CertificateSlider'
import Trustedby2 from '../../../Home/Component/TrustedBy2'
import WhySaasverseArch from './hirearchitechcompo/WhySaasverseArch'
import JustArchitecture from './hirearchitechcompo/JustArchitecture'
import { Helmet } from 'react-helmet';
export default function HireArchitech() {
  return (
    <>
     <Helmet>
        <title>Hire Top Salesforce Architects | Scalable Solutions - Saasverse</title>
        <meta name="description" content="Hire expert Salesforce architects from Saasverse to design scalable, high-performance CRM solutions tailored to your business needs." />
        <link rel="canonical" href="https://saasverse.in/hire-salesforce-architect/" />
      </Helmet>
    <HireArchitechBanner/>
    <HireArchitechBenifits/>
    <WhySaasverseArch/>
    <HireArchitechBanner2/>
    <CertificateSlider/>
    <JustArchitecture/>
    <Trustedby2/>
    <HireArchitechReadyBanner/>
    <Faq/>
      
    </>
  )
}
