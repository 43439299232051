import React from 'react'
import SitecoreBanner from './Compo/SitecoreBanner'
import SitecorePower from './Compo/SitecorePower'
import WhySitecore from './Compo/WhySitecore'
import SitecoreServices from './Compo/SitecoreServices'
import WhySitecoreS from './Compo/WhySitecoreS'
import ReadySitecore from './Compo/ReadySitecore'
import SitecoreFaq from './Compo/SitecoreFaq'
import { Helmet } from 'react-helmet';
export default function Sitecore() {
  return (
    <>
     <Helmet>
            <title>Sitecore Development Services | Scalable & Custom Solutions</title>
            <meta name="description" content="Get expert Sitecore development services for scalable, secure, and personalized digital experiences. Build powerful CMS solutions tailored to your needs." />
            <link rel="canonical" href="https://saasverse.in/salesforce-sitecore" />
          </Helmet>
      <SitecoreBanner/>
      <SitecorePower/>
      <WhySitecore/>
      <SitecoreServices/>
      <WhySitecoreS/>
      <ReadySitecore/>
      <SitecoreFaq/>
    </>
  )
}
