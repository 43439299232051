import React from 'react'
import AppExchangeBanner from './Compo/AppExchangeBanner'
import AppExchangeBanner2 from './Compo/AppExchangeBanner2'
import AppExchangeBenifits from './Compo/AppExchangeBenifits'
import AppExchangeWhy from './AppExchangeWhy'
import AppExchangeReadyBanner from './Compo/AppExchangeReadyBanner'
import Faq from '../../../Faq/Faq'
// import WhySaasverse from './Compo/WhySaasverse'
import CertificateSlider from '../../../../Home/Component/CertificateSlider'
import Trustedby2 from '../../../../Home/Component/TrustedBy2'
import ThreeColumnLayout from './Compo/ThreeColumnLayout'
import { Helmet } from 'react-helmet';





export default function SalesforceAppExchange() {
  return (
    <>
     <Helmet>
        <title>Top Salesforce AppExchange Development Company - USA, UK & Australia</title>
        <meta name="description" content="Looking for a trusted partner for your Salesforce AppExchange project? Saasverse offers expert app development services in the USA, UK & Australia. Contact us today!" />
        <link rel="canonical" href="https://saasverse.in/salesforce-appexchange-development-company" />
      </Helmet>
      <AppExchangeBanner/>
      <AppExchangeBenifits/>
      <AppExchangeBanner2/>
      <AppExchangeWhy/>
  
      {/* <WhySaasverse/> */}
     <ThreeColumnLayout/>
      <CertificateSlider/>
      <Trustedby2/>
      <AppExchangeReadyBanner/>
      <Faq/>
    </>
  )
}
