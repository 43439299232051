import {React,useEffect} from 'react'

// import WhySaasverse from '../../Services/Salesforce/SalesforceAppExchange/Compo/WhySaasverse'
import Faq from '../../Faq/Faq'
import AIBanner from './AIcompo/AIBanner'
import AIBenifits from './AIcompo/AIBenifits'
import AIBanner2 from './AIcompo/AIBanner2'
import CertificateSlider from '../../../Home/Component/CertificateSlider'
import Trustedby2 from '../../../Home/Component/TrustedBy2'
import WhySaasverseAI from './AIcompo/WhySaasverseAI'
import HowAI from './AIcompo/HowAI'
import { Helmet } from 'react-helmet';
export default function AICompany() {
  useEffect(() => {
    // Scroll to top when the Home component mounts
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures it runs only on component mount

  return (
    <>
     <Helmet>
        <title>Salesforce Einstein AI Services - Saasverse</title>
        <meta name="description" content="Saasverse provides Salesforce Einstein AI solutions to help businesses leverage AI-powered insights, automate processes, and enhance decision-making." />
        <link rel="canonical" href="https://saasverse.in/expertise/salesforce-ai-company/" />
      </Helmet>
    <AIBanner/>
    <AIBenifits/>
    <WhySaasverseAI/>
    <CertificateSlider/>
    <HowAI/>
    <Trustedby2/>
    <AIBanner2/>
    <Faq/>
      
    </>
  )
}
