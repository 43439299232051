import React from 'react'
import B2CBanner from './component/B2CBanner'
import B2BHeading from './component/B2B/B2BHeading'
import B2BServices from './component/B2B/B2BServices'
import WhyB2B from './component/B2B/WhyB2B'
import B2BReady from './component/B2B/B2BReady'
import B2CHeading from './component/B2C/B2CHeading'
import B2CServices from './component/B2C/B2CServices'
import WhyB2C from './component/B2C/WhyB2C'
import B2CReady from './component/B2C/B2CReady'
import { Helmet } from 'react-helmet';
export default function B2BC2C() {
  return (
    <>
    <Helmet>
            <title>Salesforce B2B & B2C Solutions | Expert Services - Saasverse</title>
            <meta name="description" content="Saasverse provides Salesforce B2B & B2C services to optimize sales, marketing, and customer engagement, helping businesses scale and succeed efficiently." />
            <link rel="canonical" href="https://saasverse.in/expertise/b2b-b2c/" />
          </Helmet>
      <B2CBanner/>
      <B2BHeading/>
      <B2BServices/>
      <WhyB2B/>
      <B2BReady/>

      <B2CHeading/>
      <B2CServices/>
      <WhyB2C/>
      <B2CReady/>
    </>
  )
}
