import React from 'react'


import Faq from '../../Faq/Faq'


import RetailBanner from './retailcompo/RetailBanner'
import RetailBenifits from './retailcompo/RetailBenifits'
import RetailBanner2 from './retailcompo/RetailBanner2'
import RetailReadyBanner from './retailcompo/RetailReadyBanner'
import WhySaasverseRetail from './retailcompo/WhySaasverseRetail'
import { Helmet } from 'react-helmet';
export default function Retail() {
  return (
    <>
     <Helmet>
        <title>Salesforce Solutions for the Retail Industry - Saasverse</title>
        <meta name="description" content="Saasverse offers Salesforce solutions for the retail industry to enhance customer experiences, streamline operations, optimize sales, and drive business growth." />
        <link rel="canonical" href="https://saasverse.in/retail-industry/" />
      </Helmet>
    <RetailBanner/>
    <RetailBenifits/>
    <RetailBanner2/>
    <WhySaasverseRetail/>
    <RetailReadyBanner/>
    <Faq/>
      
    </>
  )
}
