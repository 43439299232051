import React from 'react'
import KenticoBanner from './Compo/KenticoBanner'
import KenticoPower from './Compo/KenticoPower'
import WhyKentico from './Compo/WhyKentico'
import KenticoServices from './Compo/KenticoServices'
import ReadyKentico from './Compo/ReadyKentico'
import KenticoFaq from './Compo/KenticoFaq'
import WhySaasverseK from './Compo/WhySaasverseK'
import { Helmet } from 'react-helmet';
export default function Kentico() {
  return (
    <>
     <Helmet>
        <title>Kentico Development & Integration | Scalable CMS Solutions</title>
        <meta name="description" content="Looking for seamless Kentico development and integration? We help you build a secure, scalable CMS that enhances your digital experience and business growth" />
        <link rel="canonical" href="https://saasverse.in/salesforce-kentico" />
      </Helmet>
      <KenticoBanner/>
      <KenticoPower/>
      <WhyKentico/>
      <KenticoServices/>
      <WhySaasverseK/>
      <ReadyKentico/>
      <KenticoFaq/>
    </>
  )
}
