import React from 'react'
import ISVBanner from './Compo/ISVBanner'
import IsvBenifits from './Compo/IsvBenifits'

import ISVWhy from './Compo/ISVWhy'

import ISVBanner2 from './Compo/ISVBanner2'
// import ISVReadyBanner from './Compo/ISVReadyBanner'
import Faq from '../../../Faq/Faq'
// import WhySaasverse from './Compo/WhySaasverse'
import CertificateSlider from '../../../../Home/Component/CertificateSlider'
import Trustedby2 from '../../../../Home/Component/TrustedBy2'
import ThreeColumnLayout from '../SalesforceAppExchange/Compo/ThreeColumnLayout'
import { Helmet } from 'react-helmet';
export default function SalesforceISV() {
  return (
    <>
   <Helmet>
        <title>Salesforce ISV Development Company | Custom App Solutions Experts</title>
        <meta name="description" content="Looking for a trusted Salesforce ISV development partner? Saasverse builds custom Salesforce apps, offering expert services in the USA, UK, and Australia." />
        <link rel="canonical" href="https://saasverse.in/salesforce-isv-development-company" />
      </Helmet>

      <ISVBanner/>
      <IsvBenifits/>
      <ISVWhy/>
      <ThreeColumnLayout/>
      {/* <WhySaasverse/> */}
      <CertificateSlider/>
      <Trustedby2/>
      <ISVBanner2/>
      {/* <ISVReadyBanner/> */}
      <Faq/>
    </>
  )
}
