import {React,useEffect} from 'react'

import Faq from '../../Faq/Faq'

import MuleSoftBanner from './mulecompo/MuleSoftBanner'
import MuleSoftBenifits from './mulecompo/MuleSoftBenifits'
import MuleSoftBanner2 from './mulecompo/MuleSoftBanner2'
import WhySaasverseMule from './mulecompo/WhySaasverseMule'
import MuleSoftReadyBanner from './mulecompo/MuleSoftReadyBanner'
import CertificateSlider from '../../../Home/Component/CertificateSlider'
import Trustedby2 from '../../../Home/Component/TrustedBy2'
import { Helmet } from 'react-helmet';
export default function MuleSoft() {
  useEffect(() => {
    // Scroll to top when the Home component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <Helmet>
        <title>MuleSoft Development Services | API Integration Experts - Saasverse</title>
        <meta name="description" content="Saasverse offers expert MuleSoft development services to streamline API integration, connect applications, and enhance business efficiency. Contact us today!" />
        <link rel="canonical" href="https://saasverse.in/expertise/mulesoft-development/" />
      </Helmet>
    <MuleSoftBanner/>
    <MuleSoftBenifits/>
    <MuleSoftBanner2/>
    <WhySaasverseMule/>
    <CertificateSlider/>
    <Trustedby2/>
    <MuleSoftReadyBanner/>
    <Faq/>
      
    </>
  )
}
