import React from 'react'


import Faq from '../../Faq/Faq'



import EnergyBanner from './energycompo/EnergyBanner'
import EnergyBenifits from './energycompo/EnergyBenifits'
import EnergyBanner2 from './energycompo/EnergyBanner2'
import EnergyReadyBanner from './energycompo/EnergyReadyBanner'
import WhySaasverseEnergy from './energycompo/WhySaasverseEnergy'
import { Helmet } from 'react-helmet';
export default function Energy() {
  return (
    <>
    <Helmet>
        <title>Salesforce Solutions for the Energy Industry - Saasverse</title>
        <meta name="description" content="Saasverse provides Salesforce solutions for the energy industry to streamline operations, optimize resource management, and enhance customer engagement." />
        <link rel="canonical" href="https://saasverse.in/energy-industry/" />
      </Helmet>
    <EnergyBanner/>
    <EnergyBenifits/>
    <EnergyBanner2/>
    <WhySaasverseEnergy/>
    <EnergyReadyBanner/>
    <Faq/>
      
    </>
  )
}
