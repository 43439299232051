import {React,useEffect} from 'react'


import Faq from '../../Faq/Faq'

import ExperienceCloudBanner from './experiencecompo/ExperienceCloudBanner'
import ExperienceCloudBenifits from './experiencecompo/ExperienceCloudBenifits'
import ExperienceCloudBanner2 from './experiencecompo/ExperienceCloudBanner2'
import WhySaasverseExp from './experiencecompo/WhySaasverseExp'
import ExperienceCloudReadyBanner from './experiencecompo/ExperienceCloudReadyBanner'
import CertificateSlider from '../../../Home/Component/CertificateSlider'
import Trustedby2 from '../../../Home/Component/TrustedBy2'
import { Helmet } from 'react-helmet';
export default function ExperienceCloud() {
  useEffect(() => {
    // Scroll to top when the Home component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
     <Helmet>
            <title>Salesforce Experience Cloud Services - Saasverse</title>
            <meta name="description" content="Saasverse offers Salesforce Experience Cloud services to build engaging digital experiences, enhance customer interactions, and improve business collaboration." />
            <link rel="canonical" href="https://saasverse.in/expertise/experience-cloud/" />
          </Helmet>
    <ExperienceCloudBanner/>
    <ExperienceCloudBenifits/>
    <ExperienceCloudBanner2/>
    <WhySaasverseExp/>
    <CertificateSlider/>
    <Trustedby2/>
    <ExperienceCloudReadyBanner/>
    <Faq/>
      
    </>
  )
}
