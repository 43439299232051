import React from 'react'


import Faq from '../../Faq/Faq'


import MediaBanner from './mediacompo/MediaBanner'
import MediaBenifits from './mediacompo/MediaBenifits'
import MediaBanner2 from './mediacompo/MediaBanner2'
import MediaReadyBanner from './mediacompo/MediaReadyBanner'
import WhySaasverseMedia from './mediacompo/WhySaasverseMedia'
import { Helmet } from 'react-helmet';
export default function Media() {
  return (
    <>
     <Helmet>
            <title>Salesforce Solutions for the Media Industry- Saasverse</title>
            <meta name="description" content="Saasverse offers Salesforce solutions for the media industry to enhance audience engagement, automate workflows, optimize ad sales, and drive growth." />
            <link rel="canonical" href="https://saasverse.in/media-industry/" />
          </Helmet>
    <MediaBanner/>
    <MediaBenifits/>
    <MediaBanner2/>
    <WhySaasverseMedia/>
    <MediaReadyBanner/>
    <Faq/>
      
    </>
  )
}
