import React from 'react'


import Faq from '../../Faq/Faq'

import AutomotiveBanner from './autocompo/AutomotiveBanner'
import AutomotiveBenifits from './autocompo/AutomotiveBenifits'
import AutomotiveBanner2 from './autocompo/AutomotiveBanner2'
import AutomotiveReadyBanner from './autocompo/AutomotiveReadyBanner'
import WhySaasverseAuto from './autocompo/WhySaasverseAuto'
import { Helmet } from 'react-helmet';

export default function Automotive() {
  return (
    <>
     <Helmet>
        <title>Salesforce Solutions for the Automotive Industry - Saasverse</title>
        <meta name="description" content="Saasverse offers Salesforce solutions for the automotive industry to streamline operations, enhance customer experiences, and drive business growth efficiently." />
        <link rel="canonical" href="https://saasverse.in/automotive-industry/" />
      </Helmet>
    <AutomotiveBanner/>
    <AutomotiveBenifits/>
    <AutomotiveBanner2/>
    <WhySaasverseAuto/>
    <AutomotiveReadyBanner/>
    <Faq/>
      
    </>
  )
}
