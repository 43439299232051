import React from 'react'

import Case1Banner from './Compo/Case1Banner'
import HealthClientBackground from './Compo/HealthClientBackground'
import HealthClient from './Compo/HealthClient'
import HealthSolution from './Compo/HealthSolution'
import HealthImplementation from './Compo/HealthImplementation '
import HealthResult from './Compo/HealthResult'
import HealthFuture from './Compo/HealthFuture'
import HealthConclusion from './Compo/HealthConclusion'
import ContactForm from '../../Company/Contact Us/contactcompo/ContactForm'
import CertificateSlider from '../../../Home/Component/CertificateSlider'
import { Helmet } from 'react-helmet';
export default function HealthCloudCase() {
  return (
    <>
    <Helmet>
            <title>Salesforce Healthcare Cloud | Transform Healthcare Operations - Saasverse</title>
            <meta name="description" content="Transform healthcare operations with Salesforce Healthcare Cloud by Saasverse. Improve patient experiences, streamline workflows, and deliver better care solutions." />
            <link rel="canonical" href="https://saasverse.in/case-studies/salesforce-healthcare-cloud/" />
          </Helmet>
    <Case1Banner/>
    <HealthClient/>
    <HealthSolution/>
    <HealthImplementation/>
    <CertificateSlider/>
    <HealthResult/>
    <HealthFuture/>
    <HealthConclusion/>
    <ContactForm/>
   {/* <HealthClientBackground/> */}
  
   </>
  
  )
}
