import React from 'react'


import Faq from '../../Faq/Faq'

import HireDeveloperBanner from './hiredevelopercompo/HireDeveloperBanner'
import HireDeveloperBenifits from './hiredevelopercompo/HireDeveloperBenifits'
import HireDeveloperBanner2 from './hiredevelopercompo/HireDeveloperBanner2'
import HireDeveloperReadyBanner from './hiredevelopercompo/HireDeveloperReadyBanner'
import CertificateSlider from '../../../Home/Component/CertificateSlider'
import Trustedby2 from '../../../Home/Component/TrustedBy2'
import WhySaasverseDev from './hiredevelopercompo/WhySaasverseDev'
import JustDeveloper from './hiredevelopercompo/JustDeveloper'
import { Helmet } from 'react-helmet';

export default function HireDeveloper() {
  return (
    <>
     <Helmet>
            <title>Hire Salesforce Developer | Certified Experts - Saasverse</title>
            <meta name="description" content="Saasverse provides expert Salesforce developers to build custom solutions, optimize CRM functionality, and enhance business processes efficiently." />
            <link rel="canonical" href="https://saasverse.in/hire-salesforce-developers/" />
          </Helmet>
    <HireDeveloperBanner/>
    <HireDeveloperBenifits/>
    <HireDeveloperBanner2/>
    <WhySaasverseDev/>
    <CertificateSlider/>
    <JustDeveloper/>
    <Trustedby2/>
    <HireDeveloperReadyBanner/>
    <Faq/>
      
    </>
  )
}
