import React from 'react'


import Faq from '../../Faq/Faq'


import HireQualityBanner from './hirequalitycompo/HireQualityBanner'
import HireQualityBenifits from './hirequalitycompo/HireQualityBenifits'
import HireQualityBanner2 from './hirequalitycompo/HireQualityBanner2'
import HireQualityReadyBanner from './hirequalitycompo/HireQualityReadyBanner'
import CertificateSlider from '../../../Home/Component/CertificateSlider'
import Trustedby2 from '../../../Home/Component/TrustedBy2'
import WhyQuality from './hirequalitycompo/WhyQuality'

import JustQuality from './hirequalitycompo/JustQuality'
import { Helmet } from 'react-helmet';
export default function HireQuality() {
  return (
    <>
    <Helmet>
        <title>Hire Salesforce Quality Analyst | Ensure CRM Excellence - Saasverse</title>
        <meta name="description" content="Hire expert Salesforce quality analysts from Saasverse to test, optimize, and ensure high-performance, error-free CRM solutions for your business." />
        <link rel="canonical" href="https://saasverse.in/hire-salesforce-expert/" />
      </Helmet>
    <HireQualityBanner/>
    <HireQualityBenifits/>
    <HireQualityBanner2/>
    {/* <HireQualityReadyBanner/> */}
    <WhyQuality/>
    <CertificateSlider/>
    <JustQuality/>
    <Trustedby2/>
    <HireQualityReadyBanner/>
    <Faq/>
      
    </>
  )
}
