import React from 'react'
import ManagedBanner from './Compo/ManagedBanner'
import ManagedBanner2 from './Compo/ManagedBanner2'
import ManagedWhy from './Compo/ManagedWhy'
import ManagedReadyBanner from './Compo/ManagedReadyBanner'
import ManagedBenifits from './Compo/ManagedBenifits'
import Faq from '../../../Faq/Faq'
import CertificateSlider from '../../../../Home/Component/CertificateSlider'
import Trustedby2 from '../../../../Home/Component/TrustedBy2'
import { Helmet } from 'react-helmet';

export default function SalesforceManaged() {
  return (
    <>
      <Helmet>
        <title>Best Salesforce Managed Services | Expert Support by Saasverse</title>
        <meta name="description" content="Saasverse offers Salesforce managed services in the USA, UK & Australia, including implementation, training & expert support to maximize your Salesforce investment." />
        <link rel="canonical" href="https://saasverse.in/salesforce-managed-services" />
      </Helmet>
      <ManagedBanner/>
      <ManagedBenifits/>
      <ManagedBanner2/>
      <ManagedWhy/>
      <CertificateSlider/>
      <Trustedby2/>
      <ManagedReadyBanner/>
      <Faq/>
    </>
  )
}
