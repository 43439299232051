import React from 'react'
import AboutUsBanner from './compo/AboutUsBanner'
import Our from './compo/Our'
import AboutOurServices from './compo/AboutOurServices'
import WhyChooseUs from './compo/WhyChooseUs'

import Testimonial from './../../../Home/Component/Testimonial'
import AboutBanner2 from './compo/AboutBanner2'
import Gallery from './compo/Gallary'
import { Helmet } from 'react-helmet';
export default function AboutUs() {
    
    return (
       <>
       <Helmet>
               <title>About us- Salesforce Implementation Experts</title>
               <meta name="description" content="Saasverse specializes in Salesforce implementation, customization, app development, and automation, delivering tailored solutions to drive business success." />
               <link rel="canonical" href="https://saasverse.in/about-us/" />
             </Helmet>
       <AboutUsBanner/>
       <Our/>
       <AboutOurServices/>
       <AboutBanner2/>
       <Gallery/>
       <WhyChooseUs/>
      <Testimonial/>
       </>
    )
}
