import {React,useEffect} from 'react'


import Faq from '../../Faq/Faq'

import TableauBanner from './tableaucompo/TableauBanner'
import TableauBenifits from './tableaucompo/TableauBenifits'
import TableauBanner2 from './tableaucompo/TableauBanner2'
import WhySaasverseTab from './tableaucompo/WhySaasverseTab'
import TableauReadyBanner from './tableaucompo/TableauReadyBanner'
import CertificateSlider from '../../../Home/Component/CertificateSlider'
import Trustedby2 from '../../../Home/Component/TrustedBy2'
import { Helmet } from 'react-helmet';
export default function TableauCRM() {
  useEffect(() => {
    // Scroll to top when the Home component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <Helmet>
        <title>Salesforce Tableau Implementation Services | Tableau Consulting Services - Saasverse</title>
        <meta name="description" content="Saasverse offers expert Tableau consulting services to help businesses unlock insights, visualize data, and make data-driven decisions for better growth." />
        <link rel="canonical" href="https://saasverse.in/expertise/tableau-crm/" />
      </Helmet>
    <TableauBanner/>
    <TableauBenifits/>
    <TableauBanner2/>
    <WhySaasverseTab/>
    <CertificateSlider/>
    <Trustedby2/>
    <TableauReadyBanner/>
    <Faq/>
      
    </>
  )
}
