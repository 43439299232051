import React from 'react'

// import WhySaasverse from '../../Services/Salesforce/SalesforceAppExchange/Compo/WhySaasverse'
import Faq from '../../Faq/Faq'

import HireAdministratorBanner from './hireadministratorcompo/HireAdministratorBanner'
import HireAdministratorBenifits from './hireadministratorcompo/HireAdministratorBenifits'
import HireAdministratorBanner2 from './hireadministratorcompo/HireAdministratorBanner2'
import HireAdministratorReadyBanner from './hireadministratorcompo/HireAdministratorReadyBanner'
import CertificateSlider from '../../../Home/Component/CertificateSlider'
import Trustedby2 from '../../../Home/Component/TrustedBy2'
import WhySaasverseAd from './hireadministratorcompo/WhySaasverseAd'
import JustHire from './hireadministratorcompo/JustHire'
import { Helmet } from 'react-helmet';
export default function HireAdministrator() {
  return (
    <>
    <Helmet>
        <title>Hire Top Salesforce Administrators | Expert Support - Saasverse</title>
        <meta name="description" content="Saasverse provides expert Salesforce administrators to manage configurations, optimize CRM performance, and streamline business operations efficiently." />
        <link rel="canonical" href="https://saasverse.in/hire-salesforce-admin/" />
      </Helmet>
    <HireAdministratorBanner/>
    <HireAdministratorBenifits/>
    <HireAdministratorBanner2/>
    <WhySaasverseAd/>
    <CertificateSlider/>
    <JustHire/>
    <Trustedby2/>
    <HireAdministratorReadyBanner/>
    <Faq/>
      
    </>
  )
}
