import React from 'react';
import { BrowserRouter, Routes, Route ,Navigate} from 'react-router-dom';
// import Navbar from './Components/Layout/Navbar/Navbar';
import Home from './Components/Layout/Home/Home';
import Navbar2 from './Components/Layout/Navbar/Navbar2';
import Footer from './Components/Layout/Footer/Footer';
import SalesforceAppExchange from './Components/Layout/Pages/Services/Salesforce/SalesforceAppExchange/SalesforceAppExchange';
import SalesforceConsulting from './Components/Layout/Pages/Services/Salesforce/SalesforceConsulting/SalesforceConsulting';
import SalesforceCustom from './Components/Layout/Pages/Services/Salesforce/SalesforceCustom/SalesforceCustom';
import SalesforceIntegration from './Components/Layout/Pages/Services/Salesforce/SalesforceIntegration/SalesforceIntegration';
import SalesforceISV from './Components/Layout/Pages/Services/Salesforce/SalesforceISV/SalesforceISV';
import SalesforceManaged from './Components/Layout/Pages/Services/Salesforce/SalesforceManaged/SalesforceManaged';
import SalesforceReview from './Components/Layout/Pages/Services/Salesforce/SalesforceReview/SalesforceReview';
import Training from './Components/Layout/Pages/Training/Training';
import AboutUs from './Components/Layout/Pages/Company/AboutUs/AboutUs';
import './App.css'
import ContactUs from './Components/Layout/Pages/Company/Contact Us/ContactUs';
import Career from './Components/Layout/Pages/Company/Career/Career';
import SalesCloud from './Components/Layout/Pages/Expertise/SalesCloud/SalesCloud';
import ServiceCloud from './Components/Layout/Pages/Expertise/ServiceCloud/ServiceCloud';
import MarketingCloud from './Components/Layout/Pages/Expertise/MarketingCloud/MarketingCloud';
import MuleSoft from './Components/Layout/Pages/Expertise/MuleSoft Development/MuleSoft';
import HealthCloud from './Components/Layout/Pages/Expertise/HealthCloud/HealthCloud';
import ExperienceCloud from './Components/Layout/Pages/Expertise/Experience Cloud/ExperienceCloud';
import TableauCRM from './Components/Layout/Pages/Expertise/Tableau CRM/TableauCRM';
import AICompany from './Components/Layout/Pages/Expertise/AI Company/AICompany';
import MobilePublisher from './Components/Layout/Pages/Expertise/Mobile Publisher/MobilePublisher';
import CPQImplementation from './Components/Layout/Pages/Expertise/CPQ/CPQImplementation';
import Fields from './Components/Layout/Pages/Expertise/Fields/Fields';
import Financial from './Components/Layout/Pages/Expertise/Financial/Financial';
import NonProfit from './Components/Layout/Pages/Expertise/NonProfit/NonProfit';
import Automotive from './Components/Layout/Pages/Industries/Automotive/Automotive';
import HealthIndustry from './Components/Layout/Pages/Industries/HealthCare/HealthIndustry';
import Communication from './Components/Layout/Pages/Industries/Communication/Communication';
import Media from './Components/Layout/Pages/Industries/Media/Media';
import ConsumerGoods from './Components/Layout/Pages/Industries/Consumer Goods/ConsumerGoods';
import Retail from './Components/Layout/Pages/Industries/Retail/Retail';
import Education from './Components/Layout/Pages/Industries/Education/Education';
import Technology from './Components/Layout/Pages/Industries/Technology/Technology';
import Energy from './Components/Layout/Pages/Industries/Energy/Energy';
import Travel from './Components/Layout/Pages/Industries/Travel/Travel';
import HireConsultant from './Components/Layout/Pages/Hire/HireConsultant/HireConsultant';
import HireProjectManager from './Components/Layout/Pages/Hire/HireProjectManager/HireProjectManager';
import HireAdministrator from './Components/Layout/Pages/Hire/HireAdministrator/HireAdministrator';
import HireProductManager from './Components/Layout/Pages/Hire/Hire Product Manager/HireProductManager';
import HireDeveloper from './Components/Layout/Pages/Hire/Hire Developer/HireDeveloper';
import HireArchitech from './Components/Layout/Pages/Hire/Hire Architech/HireArchitech';
import HireBusiness from './Components/Layout/Pages/Hire/HireBA/HireBusiness';
import HireQuality from './Components/Layout/Pages/Hire/Hire Quality/HireQuality';
import CPQ from './Components/Layout/Pages/Training/compo/TrainingInner/CPQ/CPQ';
import AdminTraining from './Components/Layout/Pages/Training/compo/TrainingInner/AdminTraining/AdminTraining';
import DevelopmentTraining from './Components/Layout/Pages/Training/compo/TrainingInner/DevelopmentTraining/DevelopmentTraining';
import Privacy from './Components/Layout/Footer/Component/Privacy';


import Navbar from './Components/Layout/Navbar/Navbar';
import Nav from './Components/Layout/Navbar/Nav';
import MarkTraining from './Components/Layout/Pages/Training/compo/TrainingInner/MarketingTraining/MarkTraining';
import FinancialInd from './Components/Layout/Pages/Industries/Financial/FinancialInd';
import Disclaimer from './Components/Layout/Footer/Component/Disclaimer/Disclaimer';
import Terms from './Components/Layout/Footer/Component/Terms/Terms';
import FooterContent from './Components/Layout/Footer/FooterContact';
import B2BC2C from './Components/Layout/Pages/Expertise/B2BC2C/B2BC2C';
import HealthCloudCase from './Components/Layout/Pages/Case Studies/Health Cloud/HealthCloudCase';
import ServiceCaseStudy from './Components/Layout/Pages/Case Studies/Service Cloud Case Study/ServiceCaseStudy';
import SalesCaseStudy from './Components/Layout/Pages/Case Studies/Sales Cloud Case Study/SalesCaseStudy';
import ServiceAWS from './Components/Layout/Pages/Service1/Aws/ServiceAWS';
import CloudComputing from './Components/Layout/Pages/Service1/Cloud Computing/CloudComputing';
import Kentico from './Components/Layout/Pages/Service1/Kentico/Kentico';
import Sitecore from './Components/Layout/Pages/Service1/Sitecore/Sitecore';
import HomeEntry from './Components/Layout/Home/Component/HomeEntry';
import Expertise from './Components/Layout/Pages/Expertise/Expertise/Expertise';
import PreLoader from './Components/Layout/Pages/Loading/PreLoader';
// import Nav from './Components/Layout/Navbar/Nav';


// import CustomCursor from './Components/Layout/Cursor/CustomCursor';


function App() {
  
  
  return (
    <BrowserRouter>
    {/* <Navbar/> */}
  
    <Nav/>
  
        {/* <PreLoader/> */}
    {/* <Navbar2/> */}
    {/* <CustomCursor/> */}
      <Routes>
        <Route path="/" element={<Home/>} />
        {/* <Route path="/" element={<HomeEntry/>} /> */}


        <Route path="/services-aws" element={<ServiceAWS/>} />
        <Route path="/services-cloud-computing" element={<CloudComputing/>} />
        <Route path="/salesforce-kentico" element={<Kentico/>} />
        <Route path="/salesforce-sitecore" element={<Sitecore/>} />





        <Route path="/salesforce-appexchange-development-company" element={<SalesforceAppExchange/>} />
        <Route path="/salesforce-consulting-company" element={<SalesforceConsulting/>} />
        <Route path="/salesforce-custom-development-services" element={<SalesforceCustom/>} />
        <Route path="/salesforce-integration-services" element={<SalesforceIntegration/>} />
        <Route path="/salesforce-isv-development-company" element={<SalesforceISV/>} />
        <Route path="/salesforce-managed-services" element={<SalesforceManaged/>} />
        <Route path="/salesforce-security-review" element={<SalesforceReview/>} />

        <Route path="/training" element={<Training/>} />
        <Route path="/apply/salesforce-cpq" element={<CPQ />} />
        <Route path="/apply/salesforce-admin" element={<AdminTraining />} />
        <Route path="/apply/salesforce-developer" element={<DevelopmentTraining />} />
        <Route path="/apply/marketing-salesforce" element={<MarkTraining />} />

        <Route path="/about-us" element={<AboutUs/>} />

        <Route path="/privacy" element={<Privacy/>} />

        <Route path="/disclaimer" element={<Disclaimer/>} />

        <Route path="/terms" element={<Terms/>} />

        <Route path="/contact-us" element={<ContactUs/>} />

        <Route path="/career" element={<Career/>} />


        <Route path="/expertise/" element={<Expertise/>} />

        <Route path="/expertise/salesforce-sales-cloud/" element={<SalesCloud/>} />
        <Route path="/expertise/salesforce-service-cloud/" element={<ServiceCloud/>} />
        <Route path="/expertise/salesforce-marketing-cloud/" element={<MarketingCloud/>} />
        <Route path="/expertise/mulesoft-development/" element={<MuleSoft/>} />
        <Route path="/expertise/health-cloud-services/" element={<HealthCloud/>} />
        <Route path="/expertise/experience-cloud/" element={<ExperienceCloud/>} />
        <Route path="/expertise/tableau-crm/" element={<TableauCRM/>} />
        <Route path="/expertise/b2b-b2c/" element={<B2BC2C/>} />
        <Route path="/expertise/salesforce-ai-company/" element={<AICompany/>} />
        <Route path="/expertise/mobile-publisher-services/" element={<MobilePublisher/>} />
        <Route path="/expertise/salesforce-cpq/" element={<CPQImplementation/>} />
        <Route path="/expertise/field-service-lightning/" element={<Fields/>} />
        <Route path="/expertise/financial-cloud-implementation-services/" element={<Financial/>} />
        <Route path="/expertise/nonprofit-cloud/" element={<NonProfit/>} />

        <Route path="/automotive-industry" element={<Automotive/>} />
        <Route path="/healthcare-industry/" element={<HealthIndustry/>} />
        <Route path="/communications-industry/" element={<Communication/>} />
        <Route path="/media-industry/" element={<Media/>} />
        <Route path="/consumer-goods-industry/" element={<ConsumerGoods/>} />
        <Route path="/retail-industry/" element={<Retail/>} />
        <Route path="/education-industry/" element={<Education/>} />
        <Route path="/technology-industry/" element={<Technology/>} />
        <Route path="/energy-industry/" element={<Energy/>} />
        <Route path="/travels-hospitality-industry/" element={<Travel/>} />
        <Route path="/financial-industry/" element={<FinancialInd/>} />

        <Route path="/hire-salesforce-consultants/" element={<HireConsultant/>} />
        <Route path="/hire-salesforce-project-managers/" element={<HireProjectManager/>} />
        <Route path="/hire-salesforce-admin/" element={<HireAdministrator/>} />
        <Route path="/hire-salesforce-product-managers/" element={<HireProductManager/>} />
        <Route path="/hire-salesforce-developers/" element={<HireDeveloper/>} />
        <Route path="/hire-salesforce-architect/" element={<HireArchitech/>} />
        <Route path="/hire-salesforce-business-analyst/" element={<HireBusiness/>} />
        <Route path="/hire-salesforce-expert/" element={<HireQuality/>} />





        <Route path="/case-studies/salesforce-healthcare-cloud/" element={<HealthCloudCase/>} />
        <Route path="/case-studies/salesforce-sales-cloud/" element={<SalesCaseStudy/>} />
        <Route path="/case-studies/salesforce-service-cloud/" element={<ServiceCaseStudy/>} />




        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <FooterContent/>
      <Footer/>
    
    </BrowserRouter>
  );
}

export default App;
