import React from 'react'
import ReviewBanner from './Compo/ReviewBanner'

import ReviewBenifits from './Compo/ReviewBenifits'
import ReviewBanner2 from './Compo/ReviewBanner2'
import ReviewWhy from './Compo/ReviewWhy'
import ReviewReadyBanner from './Compo/ReviewReadyBanner'
import Faq from '../../../Faq/Faq'
import CertificateSlider from '../../../../Home/Component/CertificateSlider'
import Trustedby2 from '../../../../Home/Component/TrustedBy2'
import { Helmet } from 'react-helmet';
export default function SalesforceReview() {
  return (
    <>
        <Helmet>
        <title>Salesforce Security Review Services | Protect & Comply - Saasverse</title>
        <meta name="description" content="Saasverse offers expert Salesforce Security Review services in the USA, UK & Australia. Ensure compliance, protect data, and secure your Salesforce apps." />
        <link rel="canonical" href="https://saasverse.in/salesforce-security-review" />
      </Helmet>
      <ReviewBanner/>
      <ReviewBenifits/>
      <ReviewBanner2/>
      <ReviewWhy/>
      <CertificateSlider/>
      <Trustedby2/>
      <ReviewReadyBanner/>
      <Faq/>
    </>
  )
}
