import {React,useEffect} from 'react'
import SalesCloudBanner from './salescompo/SalesCloudBanner'
import SalesCloudBenifits from './salescompo/SalesCloudBenifits'
import SalesCloudBanner2 from './salescompo/SalesCloudBanner2'
import WhySaasverse from '../../Services/Salesforce/SalesforceAppExchange/Compo/WhySaasverse'
import Faq from '../../Faq/Faq'
import SalesCloudReadyBanner from './salescompo/SalesCloudReadyBanner'
import CertificateSlider from '../../../Home/Component/CertificateSlider'
import Trustedby2 from '../../../Home/Component/TrustedBy2'
import { Helmet } from 'react-helmet';
export default function SalesCloud() {
  useEffect(() => {
    // Scroll to top when the Home component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
     <Helmet>
        <title>Salesforce Sales Cloud Consulting Service - Saasverse</title>
        <meta name="description" content="Saasverse offers expert Salesforce Sales Cloud consulting services to help businesses optimize sales processes, improve efficiency, and drive revenue growth." />
        <link rel="canonical" href="https://saasverse.in/expertise/salesforce-sales-cloud/" />
      </Helmet>
    <SalesCloudBanner/>
    <SalesCloudBenifits/>
    <SalesCloudBanner2/>
    <WhySaasverse/>
    <CertificateSlider/>
    <Trustedby2/>
    <SalesCloudReadyBanner/>
    <Faq/>
      
    </>
  )
}
