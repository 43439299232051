import React from 'react'

import WhySaasverse from '../../Services/Salesforce/SalesforceAppExchange/Compo/WhySaasverse'
import Faq from '../../Faq/Faq'

import NonProfitBanner from './nonprofitcompo/NonProfitBanner'
import NonProfitBenifits from './nonprofitcompo/NonProfitBenifits'
import NonProfitBanner2 from './nonprofitcompo/NonProfitBanner2'
import CertificateSlider from '../../../Home/Component/CertificateSlider'
import Trustedby2 from '../../../Home/Component/TrustedBy2'
import NonProfitReadyBanner from './nonprofitcompo/NonProfitReadyBanner'
import WhySaasverseNon from './nonprofitcompo/WhySaasverseNon'
import { Helmet } from 'react-helmet';
export default function NonProfit() {
  return (
    <>
     <Helmet>
            <title>Salesforce Nonprofit Cloud Consultant Services - Saasverse</title>
            <meta name="description" content="Saasverse offers expert Salesforce Nonprofit Cloud consulting services to help organizations streamline operations, enhance donor engagement, and drive impact." />
            <link rel="canonical" href="https://saasverse.in/expertise/nonprofit-cloud/" />
          </Helmet>
    <NonProfitBanner/>
    <NonProfitBenifits/>
    <NonProfitBanner2/>
    <WhySaasverseNon/>
    <CertificateSlider/>
    <Trustedby2/>
   <NonProfitReadyBanner/>
    <Faq/>
      
   </>
  )
}
