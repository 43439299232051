import React from 'react'
import AWSBanner from './compo/AWSBanner'
import AWSServices from './compo/AWSServices'
import WhyAWS from './compo/WhyAWS'
import WhySaasverse from './compo/WhySaasverse'
import ReadyAWS from './compo/ReadyAWS'
import AWSFaq from './compo/AWSFaq'
import AWSPower from './compo/AWSPower'
import { Helmet } from 'react-helmet';
export default function ServiceAWS() {
  return (
    <>
     <Helmet>
        <title>AWS Development Services | Amazon Cloud Application Consulting</title>
        <meta name="description" content="Get expert AWS Development Services for scalable, secure, and cost-effective cloud solutions. Boost performance, security & reliability with our AWS expertise." />
        <link rel="canonical" href="https://saasverse.in/services-aws" />
      </Helmet>
      <AWSBanner/>
      <AWSPower/>
      <WhyAWS/>
      <AWSServices/>
      <WhySaasverse/>
      <ReadyAWS/>
      <AWSFaq/>
    </>
  )
}
