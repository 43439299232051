import React from 'react'

import Faq from '../../Faq/Faq'



import TravelBanner from './travelcompo/TravelBanner'
import TravelBenifits from './travelcompo/TravelBenifits'
import TravelBanner2 from './travelcompo/TravelBanner2'
import TravelReadyBanner from './travelcompo/TravelReadyBanner'
import WhySaasverseTravel from './travelcompo/WhySaasverseTravel'
import { Helmet } from 'react-helmet';
export default function Travel() {
  return (
    <>
    <Helmet>
        <title>Salesforce Solutions for the Travel & Hospitality Industry - Saasverse</title>
        <meta name="description" content="Saasverse offers Salesforce solutions for the travel and hospitality industry to enhance customer experiences, streamline bookings, and boost operational efficiency." />
        <link rel="canonical" href="https://saasverse.in/travels-hospitality-industry/" />
      </Helmet>
    <TravelBanner/>
    <TravelBenifits/>
    <TravelBanner2/>
    <WhySaasverseTravel/>
    <TravelReadyBanner/>
    <Faq/>
      
    </>
  )
}
