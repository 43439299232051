import React from 'react'
import SalesCaseBanner from './compo/SalesCaseBanner'
import SalesClient from './compo/SalesClient'
import SalesSolution from './compo/SalesSolution'
import SalesImplementation from './compo/SalesImplementation'
import SalesResult from './compo/SalesResult'
import SalesFuture from './compo/SalesFuture'
import SalesConclusion from './compo/SalesConclusion'
import ContactForm from '../../Company/Contact Us/contactcompo/ContactForm'
import CertificateSlider from '../../../Home/Component/CertificateSlider'
import { Helmet } from 'react-helmet';
export default function SalesCaseStudy() {
  return (
    <>
    <Helmet>
        <title>Salesforce Sales Cloud | Boost Sales Performance - Saasverse</title>
        <meta name="description" content="Unlock the power of Salesforce Sales Cloud with Saasverse. Streamline sales, enhance customer relationships, and boost revenue growth with efficient solutions." />
        <link rel="canonical" href="https://saasverse.in/case-studies/salesforce-sales-cloud/" />
      </Helmet>
      <SalesCaseBanner/>
      <SalesClient/>
      <SalesSolution/>
      <SalesImplementation/>
      <CertificateSlider/>
      <SalesResult/>
      <SalesFuture/>
      <SalesConclusion/>
      <ContactForm/>

    </>
  )
}
