import React from 'react'
import CustomBanner from './Compo/CustomBanner'
import CustomBenifits from './Compo/CustomBenifits'
import CustomBanner2 from './Compo/CustomBanner2'
import CustomWhy from './Compo/CustomWhy'
import CustomReady from './Compo/CustomReady'
import Faq from '../../../Faq/Faq'
import ThreeColumnLayout from '../SalesforceAppExchange/Compo/ThreeColumnLayout'
import Trustedby2 from '../../../../Home/Component/TrustedBy2'
import CertificateSlider from '../../../../Home/Component/CertificateSlider'
import { Helmet } from 'react-helmet';
export default function SalesforceCustom() {
  return (
    <>
      <Helmet>
        <title>Salesforce Custom Development Experts | USA, UK & Australia</title>
        <meta name="description" content="Saasverse provides Salesforce custom development services in the USA, UK & Australia, delivering tailored solutions to optimize processes and drive growth." />
        <link rel="canonical" href="https://saasverse.in/salesforce-custom-development-services" />
      </Helmet>
      <CustomBanner/>
      <CustomBenifits/>
      <CustomBanner2/>
      <CustomWhy/>
      <ThreeColumnLayout/>
      <CertificateSlider/>
      <Trustedby2/>
      <CustomReady/>
      <Faq/>

    </>
  )
}
