// Terms.js
import React from 'react';
import './Terms.css'; // Import the CSS file for styling
import TermsBanner from './TermsBanner';
import { Helmet } from 'react-helmet';
const Terms = () => {
  return (
    <>
     <Helmet>
            <title>Terms & Conditions - Saasverse Pvt. Ltd.</title>
            <meta name="description" content="Review the Terms & Conditions for using Saasverse Pvt. Ltd.'s services and website. Understand your rights and obligations when engaging with us." />
            <link rel="canonical" href="https://saasverse.in/terms" />
          </Helmet>
    <TermsBanner/>
    <div className="terms">
      <h2>Terms and Conditions</h2>

      <h3>1. Introduction</h3>
      <p>These Terms and Conditions govern your use of the Saasverse Private Limited website and any services, products, or courses offered by Saasverse Private Limited. By accessing or using the Website or any of our services, you agree to be bound by these Terms.</p>

      <h3>2. General Use and Eligibility</h3>
      <p><strong>2.1.</strong> By using our Website or services, you represent that you are at least 18 years old and capable of forming a legally binding contract.</p>
      <p><strong>2.2.</strong> You agree to use our Website and services in compliance with all applicable laws and regulations.</p>

      <h3>3. User Accounts</h3>
      <p><strong>3.1.</strong> You may need to create an account to access certain features of our Website or to enroll in our courses. You are responsible for maintaining the confidentiality of your account information and for any activity that occurs under your account.</p>
      <p><strong>3.2.</strong> You agree to provide accurate, current, and complete information during the account registration process and to update your information as necessary to keep it accurate.</p>

      <h3>4. Intellectual Property</h3>
      <p><strong>4.1.</strong> All content on our Website, including text, graphics, logos, images, videos, and other materials, is protected by copyright, trademark, and other intellectual property rights owned by Saasverse or our content providers.</p>
      <p><strong>4.2.</strong> You may not reproduce, distribute, display, or create derivative works of any content on the Website without our prior written consent.</p>

      <h3>5. Courses and Services</h3>
      <p><strong>5.1.</strong> Enrolling in a course or service provided by Saasverse is subject to payment of applicable fees, which are non-refundable unless otherwise specified.</p>
      <p><strong>5.2.</strong> We reserve the right to modify or discontinue any course or service at our discretion. In the event of such a change, you will be provided with an alternative or refund, as determined by Saasverse.</p>
      <p><strong>5.3.</strong> We strive to provide accurate information, but we do not guarantee the accuracy, completeness, or reliability of the content provided in our courses or services.</p>

      <h3>6. Client Projects</h3>
      <p><strong>6.1.</strong> If you engage Saasverse for Salesforce project work, the scope of the project, timeline, and pricing will be defined in a separate written agreement or statement of work.</p>
      <p><strong>6.2.</strong> Any change in project scope or terms must be agreed upon in writing by both parties.</p>

      <h3>7. Privacy</h3>
      <p><strong>7.1.</strong> Your use of the Website and our services is also governed by our Privacy Policy, which you can find on our Website.</p>

      <h3>8. Limitation of Liability</h3>
      <p><strong>8.1.</strong> To the fullest extent permitted by applicable law, Saasverse shall not be liable for any direct, indirect, incidental, special, or consequential damages, including, but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses.</p>

      <h3>9. Termination</h3>
      <p><strong>9.1.</strong> We reserve the right to terminate or suspend your account or access to our Website or services for any reason, including violation of these Terms, at our sole discretion.</p>

      <h3>10. Changes to Terms</h3>
      <p><strong>10.1.</strong> We may update these Terms from time to time. Your continued use of the Website or our services after any changes constitute your acceptance of the revised Terms.</p>

      <h3>11. Contact Us</h3>
      <p><strong>11.1.</strong> If you have any questions or concerns about these Terms or our services, please contact us at <a href="mailto:learning@saasverse.in">learning@saasverse.in</a>.</p>

      <p>By using our Website and services, you acknowledge that you have read, understood, and agree to these Terms and Conditions.</p>
    </div>
    </>
  );
};

export default Terms;
