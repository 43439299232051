import React from 'react'


import Faq from '../../Faq/Faq'



import TechnologyBanner from './technologycompo/TechnologyBanner'
import TechnologyBenifits from './technologycompo/TechnologyBenifits'
import TechnologyBanner2 from './technologycompo/TechnologyBanner2'
import TechnologyReadyBanner from './technologycompo/TechnologyReadyBanner'
import WhySaasverseTech from './technologycompo/WhySaasverseTech'
import { Helmet } from 'react-helmet';
export default function Technology() {
  return (
    <>
     <Helmet>
        <title>Salesforce Solutions for the Technology Industry - Saasverse</title>
        <meta name="description" content="Saasverse offers Salesforce solutions for the technology industry to optimize workflows, improve customer engagement, and accelerate business growth." />
        <link rel="canonical" href="https://saasverse.in/technology-industry/" />
      </Helmet>
    <TechnologyBanner/>
    <TechnologyBenifits/>
    <TechnologyBanner2/>
    <WhySaasverseTech/>
    <TechnologyReadyBanner/>
    <Faq/>
      
    </>
  )
}
