import React from 'react'

// import WhySaasverse from '../../Services/Salesforce/SalesforceAppExchange/Compo/WhySaasverse'
import Faq from '../../Faq/Faq'





import HireConsultantBanner from './hireconsultantcompo/HireConsultantBanner'
import HireConsultantBenifits from './hireconsultantcompo/HireConsultantBenifits'
import HireConsultantBanner2 from './hireconsultantcompo/HireConsultantBanner2'
import HireConsultantReadyBanner from './hireconsultantcompo/HireConsultantReadyBanner'
import CertificateSlider from '../../../Home/Component/CertificateSlider'
import Trustedby2 from '../../../Home/Component/TrustedBy2'
import WhySaasverseCon from './hireconsultantcompo/WhySaasverseCon'
import { Helmet } from 'react-helmet';
export default function HireConsultant() {
  return (
    <>
    <Helmet>
        <title>Hire Top Salesforce Consultants | Trusted Experts - Saasverse</title>
        <meta name="description" content="Saasverse Pvt Ltd is a leading Salesforce consulting firm dedicated to helping businesses of all sizes unlock the full potential of the Salesforce platform." />
        <link rel="canonical" href="https://saasverse.in/hire-salesforce-consultants/" />
      </Helmet>
    <HireConsultantBanner/>
    <HireConsultantBenifits/>
    <HireConsultantBanner2/>
    <WhySaasverseCon/>
    <CertificateSlider/>
    <Trustedby2/>
    <HireConsultantReadyBanner/>
    <Faq/>
      
    </>
  )
}
