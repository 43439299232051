import {React,useEffect} from 'react'


import Faq from '../../Faq/Faq'
import MarketingCloudBanner from './marketingcompo/MarketingCloudBanner'
import MarketingCloudBenifits from './marketingcompo/MarketingCloudBenifits'
import MarketingCloudBanner2 from './marketingcompo/MarketingCloudBanner2'
import WhySaasverseMark from './marketingcompo/WhySaasverseMark'
import MarketingCloudReadyBanner from './marketingcompo/MarketingCloudReadyBanner'
import CertificateSlider from '../../../Home/Component/CertificateSlider'
import Trustedby2 from '../../../Home/Component/TrustedBy2'
import { Helmet } from 'react-helmet';
export default function MarketingCloud() {
  useEffect(() => {
    // Scroll to top when the Home component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
     <Helmet>
        <title>Best Salesforce Marketing Cloud Services - Saasverse</title>
        <meta name="description" content="Saasverse provides expert Salesforce Marketing Cloud services to help businesses automate campaigns, personalize customer journeys, and boost engagement." />
        <link rel="canonical" href="https://saasverse.in/expertise/salesforce-marketing-cloud/" />
      </Helmet>
    <MarketingCloudBanner/>
    <MarketingCloudBenifits/>
    <MarketingCloudBanner2/>
    <WhySaasverseMark/>
    <CertificateSlider/>
    <Trustedby2/>
  <MarketingCloudReadyBanner/>
    <Faq/>
      
    </>
  )
}
