import React from 'react'

// import WhySaasverse from '../../Services/Salesforce/SalesforceAppExchange/Compo/WhySaasverse'
import Faq from '../../Faq/Faq'

import HireProjectBanner from './hireprojectcompo/HireProjectBanner'
import HireProjectBenifits from './hireprojectcompo/HireProjectBenifits'
import HireProjectBanner2 from './hireprojectcompo/HireProjectBanner2'
import HireProjectReadyBanner from './hireprojectcompo/HireProjectReadyBanner'
import CertificateSlider from '../../../Home/Component/CertificateSlider'
import Trustedby2 from '../../../Home/Component/TrustedBy2'
import WhySaasverseProject from './hireprojectcompo/WhySaasverseProject'
import JustProject from './hireprojectcompo/JustProject'
import { Helmet } from 'react-helmet';
export default function HireProjectManager() {
  return (
    <>
     <Helmet>
            <title>Hire Salesforce Project Managers | Expert Management - Saasverse</title>
            <meta name="description" content="Saasverse offers skilled Salesforce project managers to ensure smooth implementation, efficient workflows, and successful project execution for your business." />
            <link rel="canonical" href="https://saasverse.in/hire-salesforce-project-managers/" />
          </Helmet>
    <HireProjectBanner/>
    <HireProjectBenifits/>
    <HireProjectBanner2/>
    <WhySaasverseProject/>
    <CertificateSlider/>
    <JustProject/>
    <Trustedby2/>
    <HireProjectReadyBanner/>
    <Faq/>
      
    </>
  )
}
