import React, { useRef } from 'react';
import CareerBanner from './Compo/CareerBanner'
import WhyWork from './Compo/WhyWork'
import JobOpenings from './Compo/JobOpenings'
import Syn from './Compo/Syn'
import HowToApply from './Compo/HowToApply'
import { Helmet } from 'react-helmet';
export default function Career() {
  const jobOpeningsRef = useRef(null); // Create a ref for the JobOpenings component

  const handleScrollToJobOpenings = () => {
    jobOpeningsRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
    <Helmet>
        <title>Careers at Saasverse | Join Our Salesforce Consulting Team</title>
        <meta name="description" content="Explore career opportunities at Saasverse. Join our team of passionate professionals and contribute to transforming businesses through cutting-edge technology." />
        <link rel="canonical" href="https://saasverse.in/career/" />
      </Helmet>
      {/* <CareerBanner/> */}
      <CareerBanner onScrollToJobOpenings={handleScrollToJobOpenings} />
      <WhyWork/>
      {/* <Syn/> */}
      <div ref={jobOpeningsRef}>
        <JobOpenings />
      </div>
      {/* <JobOpenings/> */}
      <HowToApply/>
    </>
  )
}
