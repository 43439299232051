import React from 'react'

// import WhySaasverse from '../../Services/Salesforce/SalesforceAppExchange/Compo/WhySaasverse'
import Faq from '../../Faq/Faq'


import CommunicationBanner from './communicationcompo/CommunicationBanner'
import CommunicationBenifits from './communicationcompo/CommunicationBenifits'
import CommunicationBanner2 from './communicationcompo/CommunicationBanner2'
import CommunicationReadyBanner from './communicationcompo/CommunicationReadyBanner'
import WhySaasverseComm from './communicationcompo/WhySaasverseComm'
import { Helmet } from 'react-helmet';
export default function Communication() {
  return (
    <>
    <Helmet>
        <title>Salesforce Solutions for the Communications Industry- Saasverse</title>
        <meta name="description" content="Saasverse provides Salesforce solutions for the communications industry, offering a unified customer journey, automated order management, and self-service." />
        <link rel="canonical" href="https://saasverse.in/communications-industry/" />
      </Helmet>
    <CommunicationBanner/>
    <CommunicationBenifits/>
    <CommunicationBanner2/>
    <WhySaasverseComm/>
    <CommunicationReadyBanner/>
    <Faq/>
      
    </>
  )
}
