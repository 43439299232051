import React from 'react'

// import WhySaasverse from '../../Services/Salesforce/SalesforceAppExchange/Compo/WhySaasverse'
import Faq from '../../Faq/Faq'
import FieldsBanner from './fieldscompo/FieldsBanner'
import FieldsBenifits from './fieldscompo/FieldsBenifits'
import FieldsBanner2 from './fieldscompo/FieldsBanner2'
import CertificateSlider from '../../../Home/Component/CertificateSlider'
import Trustedby2 from '../../../Home/Component/TrustedBy2'
import FieldsReadyBanner from './fieldscompo/FieldsReadyBanner'
import WhySaasverseField from './fieldscompo/WhySaasverseField'
import FieldNext from './fieldscompo/FieldNext'
import { Helmet } from 'react-helmet';
export default function Fields() {
  return (
    <>
     <Helmet>
            <title>Salesforce Field Service Lightning Company - Saasverse</title>
            <meta name="description" content="Saasverse offers Salesforce Field Service Lightning solutions to optimize scheduling, automate workflows, and enhance on-site service efficiency." />
            <link rel="canonical" href="https://saasverse.in/expertise/field-service-lightning/" />
          </Helmet>
    <FieldsBanner/>
    <FieldsBenifits/>
    <FieldsBanner2/>
    <WhySaasverseField/>
    <CertificateSlider/>
    <FieldNext/>
    <Trustedby2/>
    <FieldsReadyBanner/>
    <Faq/>
      
    </>
  )
}
