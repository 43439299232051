import React from 'react'
import IntegrationBanner from './Compo/IntegrationBanner'
import IntegrationBanner2 from './Compo/IntegrationBanner2'
import IntegrationBenifits from './Compo/IntegrationBenifits'
import IntegrationWhy from './Compo/IntegrationWhy'
import IntegrationReady from './Compo/IntegrationReady'
import Faq from '../../../Faq/Faq'
import CertificateSlider from '../../../../Home/Component/CertificateSlider'
import TrustedPartner from '../../../../Home/Component/TrustedPartner'
import Trustedby2 from '../../../../Home/Component/TrustedBy2'
import Litify from './Compo/Litify'
import ContactForm from '../../../Company/Contact Us/contactcompo/ContactForm'
import { Helmet } from 'react-helmet';
export default function SalesforceIntegration() {
  return (
    <>
      <Helmet>
        <title>Best Salesforce Integration Services | Seamless Solutions by Saasverse</title>
        <meta name="description" content="Saasverse helps businesses streamline operations with expert Salesforce integration services. Connect your apps, automate workflows, and boost efficiency." />
        <link rel="canonical" href="https://saasverse.in/salesforce-integration-services" />
      </Helmet>
      <IntegrationBanner/>
      <IntegrationBenifits/>
      <Litify/>
      <IntegrationBanner2/>
      <IntegrationWhy/>
      <CertificateSlider/>
      <Trustedby2/>
      <IntegrationReady/>
      <Faq/>
      <ContactForm/>
     
    </>
  )
}
// <ISVBanner/>
// <IsvBenifits/>
// <ISVBanner2/>
// <ISVWhy/>
// <ISVReadyBanner/>