import React from 'react'


import Faq from '../../Faq/Faq'



import EducationBanner from './educationcompo/EducationBanner'
import EducationBenifits from './educationcompo/EducationBenifits'
import EducationBanner2 from './educationcompo/EducationBanner2'
import EducationReadyBanner from './educationcompo/EducationReadyBanner'
import WhySaasverseEdu from './educationcompo/WhySaasverseEdu'
import { Helmet } from 'react-helmet';
export default function Education() {
  return (
    <>
     <Helmet>
        <title>Salesforce Solutions for the Education Industry - Saasverse</title>
        <meta name="description" content="Saasverse provides Salesforce solutions for the education industry to enhance student engagement, streamline administration, and improve learning experiences." />
        <link rel="canonical" href="https://saasverse.in/education-industry/" />
      </Helmet>
    <EducationBanner/>
    <EducationBenifits/>
    <EducationBanner2/>
    <WhySaasverseEdu/>
    <EducationReadyBanner/>
    <Faq/>
      
    </>
  )
}
