import React from 'react'


import Faq from '../../Faq/Faq'


import ConsumerBanner from './consumercompo/ConsumerBanner'
import ConsumerBenifits from './consumercompo/ConsumerBenifits'
import ConsumerBanner2 from './consumercompo/ConsumerBanner2'
import ConsumerReadyBanner from './consumercompo/ConsumerReadyBanner'
import WhySaasverseConsumer from './consumercompo/WhySaasverseConsumer'
import { Helmet } from 'react-helmet';
export default function ConsumerGoods() {
  return (
    <>
     <Helmet>
        <title>Salesforce Solutions for the Consumer Goods Industry - Saasverse"</title>
        <meta name="description" content="Saasverse provides Salesforce solutions for the consumer goods industry to optimize sales, streamline supply chains, enhance customer engagement, and drive growth." />
        <link rel="canonical" href="https://saasverse.in/communications-industry/" />
      </Helmet>
    <ConsumerBanner/>
    <ConsumerBenifits/>
    <ConsumerBanner2/>
    <WhySaasverseConsumer/>
    <ConsumerReadyBanner/>
    <Faq/>
      
    </>
  )
}
