import React from 'react'
import CloudBanner from './compo/CloudBanner'
import CloudPower from './compo/CloudPower'
import WhyCloud from './compo/WhyCloud'
import CloudServices from './compo/CloudServices'
import WhySaasverseC from './compo/WhySaasverseC'
import ReadyCloud from './compo/ReadyCloud'
import CloudFaq from './compo/CloudFaq'
import { Helmet } from 'react-helmet';
export default function CloudComputing() {
  return (
    <>
     <Helmet>
            <title>Cloud Consulting Services & Solutions - Saasverse</title>
            <meta name="description" content="SaaSverse offers cloud computing consulting, including strategy, migration, security, and more, ensuring a robust and scalable cloud architecture for your business." />
            <link rel="canonical" href="https://saasverse.in/services-cloud-computing" />
          </Helmet>
      <CloudBanner/>
      <CloudPower/>
      <WhyCloud/>
      <CloudServices/>
      <WhySaasverseC/>
      <ReadyCloud/>
      <CloudFaq/>
    </>
  )
}