import React from 'react'

import Faq from '../../Faq/Faq'


import CPQSBanner from './cpqcompo/CPQBanner'
import CPQBenifits from './cpqcompo/CPQBenifits'
import CPQBanner2 from './cpqcompo/CPQBanner2'
import WhySaasverseCPQ from './cpqcompo/WhySaasverseCPQ'
import CPQReadyBanner from './cpqcompo/CPQReadyBanner'
import CertificateSlider from '../../../Home/Component/CertificateSlider'
import Trustedby2 from '../../../Home/Component/TrustedBy2'
import { Helmet } from 'react-helmet';
export default function CPQImplementation() {
  return (
    <>
    <Helmet>
        <title>Salesforce CPQ Implementation Services - Saasverse</title>
        <meta name="description" content="Saasverse provides Salesforce CPQ implementation services to streamline pricing, automate quotes, and enhance sales efficiency for your business." />
        <link rel="canonical" href="https://saasverse.in/expertise/salesforce-cpq/" />
      </Helmet>
    <CPQSBanner/>
    <CPQBenifits/>
    <CPQBanner2/>
    <WhySaasverseCPQ/>
    <CertificateSlider/>
    <Trustedby2/>
    <CPQReadyBanner/>
    <Faq/>
      
    </>
  )
}
