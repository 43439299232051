import React from 'react';

// import Pricing from './Pricing';
import './compo/css/Training.css'
import TrainingBanner from './compo/TrainningBanner';
import TrainingPart from './compo/TrainingPart';
import TrainingContact from './compo/TrainingContact';
import TrainingService from './compo/TrainingService';
import TrainingType from './compo/TrainingType';


import Batches from './compo/Batches';
import ThreeColumnLayout from '../Services/Salesforce/SalesforceAppExchange/Compo/ThreeColumnLayout';
import TrainingBanner2 from './compo/TrainingBanner2';
import TrainingFormat from './compo/TrainingFormat';
import TrainingPartner from './compo/TrainingPartner';
import Batch2 from './compo/Batch2';
import { Helmet } from 'react-helmet';
export default function Training() {
  return (
<>
<Helmet>
        <title>Salesforce Training & Certifications | Learn from Experts - Saasverse</title>
        <meta name="description" content="Gain in-depth Salesforce training & certifications with Saasverse. Learn from experts, enhance your skills, and advance your career with top-notch training." />
        <link rel="canonical" href="https://saasverse.in/training/" />
      </Helmet>
<TrainingBanner/>
<TrainingPart/>
<TrainingPartner/>
<TrainingContact/>
<TrainingService/>
{/* <Batches/> */}
<Batch2/>
<ThreeColumnLayout/>
<TrainingType/>
<TrainingFormat/>
<TrainingBanner2/>

</>
  );
}
