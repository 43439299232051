import React from 'react'


import Faq from '../../Faq/Faq'


import HealthIndustryBanner from './healthindustrycompo/HealthIndustryBanner'
import HealthIndustryBenifits from './healthindustrycompo/HealthIndustryBenifits'
import HealthIndustryBanner2 from './healthindustrycompo/HealthIndustryBanner2'
import HealthIndustryReadyBanner from './healthindustrycompo/HealthIndustryReadyBanner'
import WhySaasverseHealth from './healthindustrycompo/WhySaasverseHealth'
import { Helmet } from 'react-helmet';
export default function HealthIndustry() {
  return (
    <>
     <Helmet>
        <title>Salesforce Solutions for the Healthcare Industry- Saasverse</title>
        <meta name="description" content="Saasverse provides Salesforce solutions for the healthcare industry to improve patient management, streamline operations, and enhance care delivery." />
        <link rel="canonical" href="https://saasverse.in/healthcare-industry/" />
      </Helmet>
    <HealthIndustryBanner/>
    <HealthIndustryBenifits/>
    <HealthIndustryBanner2/>
    <WhySaasverseHealth/>
    <HealthIndustryReadyBanner/>
    <Faq/>
      
    </>
  )
}
